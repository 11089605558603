// Define the type of your translation table
interface TranslationTable {
    [key: string]: { tr: string };
}

// Declare your translation table
const translationTable: TranslationTable = {
    "Type a message...": {
        tr: "Bir mesaj yaz..."
    },
    "Stop generating": {
        tr: "Durdur"
    },
    "Generating answer...": {
        tr: "Oluşturuluyor..."
    },
    "Show chat history": {
        tr: "Sohbet geçmişi"
    },
    "Hide chat history": {
        tr: "Sohbet geçmişi"
    },
    "AI-generated responses may contain inaccuracies. Verify important information.": {
        tr: "Yapay zeka, doğru olmayan bilgiler gösterebilir. Bu nedenle, yanıtların doğru olup olmadığını kontrol edin."
    },
    "What will you teach today?": {
        tr: "Bugün ne öğreteceksin?"
    },
    "Twin AI Assistant is tailored to support you in creating engaging lessons.": {
        tr: "Twin AI Asistanı size etkili ders hazırlamada yardım etmek için hazırlanmıştır"
    },   
    "Chat history": {
        tr: "Sohbet geçmişi"
    },
    "Clear all chat history": {
        tr: "Geçmişi temizle"
    },
    "Recent": {
        tr: "En son"
    }
};

// Your translation function
export function _t(input: string, lang: string = "en"): string {
    let returnValue = input;
    if (lang === "tr") { // Check if input is a valid key
        returnValue = translationTable[input]?.tr;
    }
    return returnValue || input;
}

